import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import {
  setCreateProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
} from "./ModalSlice";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { addUserProject, auth } from "../../app/firebase";
import { setCurrentSelectedProject } from "../Home/HomeSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ADDR } from "../../config/config";
import { LoaderModal } from "./LoaderModal";
import { ErrorModal } from "./ErrorModal";

const CreateProjectModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useAppSelector(
    (state) => state.modalSlice.createProjectModal.status,
  );
  // const projectName = useAppSelector((state) => state.modalSlice.createProjectModal.projectName);
  // const usernameList = useAppSelector((state) => state.modalSlice.createProjectModal.usernames);
  const createdDate = useAppSelector(
    (state) => state.modalSlice.createProjectModal.createdDate,
  );
  const lastModified = useAppSelector(
    (state) => state.modalSlice.createProjectModal.lastModified,
  );
  const lastUsed = useAppSelector(
    (state) => state.modalSlice.createProjectModal.lastUsed,
  );
  const userTypeR = useAppSelector((state) => state.homeSlice.userType.value);

  const createProjectModalObject = useAppSelector(
    (state) => state.modalSlice.createProjectModal,
  );

  const usernameList = createProjectModalObject.usernames || [];
  const projectName = createProjectModalObject.projectName || "";

  const [newUsername, setNewUsername] = useState("");
  const [user] = useAuthState(auth);

  const closeModal = () => {
    dispatch(
      setCreateProjectModal({
        status: false,
        projectName: "",
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [],
      }),
    );
  };

  const handleProjectNameChange = (e) => {
    const updatedModal = {
      ...createProjectModalObject, // Already fetched via `useAppSelector`
      projectName: e.target.value,
    };

    dispatch(setCreateProjectModal(updatedModal));
  };

  const addUsernameHandler = () => {
    if (newUsername.trim() !== "") {
      const updatedModal = {
        ...createProjectModalObject, // Already fetched via `useAppSelector`
        usernames: [...usernameList, newUsername.trim()],
      };

      dispatch(setCreateProjectModal(updatedModal));
      setNewUsername(""); // Clear input
    }
  };

  const removeUsernameHandler = (username) => {
    const updatedModal = {
      ...createProjectModalObject, // Already fetched via `useAppSelector`
      usernames: usernameList.filter((user) => user !== username),
    };

    dispatch(setCreateProjectModal(updatedModal));
  };

  const createProjectInDB = async () => {
    if (!projectName) {
      dispatch(
        setErrorModalStatus({ status: true, message: "Enter Project Name" }),
      );
      return;
    }
    if (!usernameList.length) {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Add at least one username.",
        }),
      );
      return;
    }

    dispatch(
      setLoaderModalStatus({ status: true, message: "Creating Project" }),
    );
    closeModal();

    try {
      let uid = user.uid;
      if (userTypeR === "User") {
        const response = await axios.post(`${ADDR}/getAdminUIDByUserEmail`, {
          email: user.email,
        });
        uid = response.data[0].AddedByUserID;
      }

      const existingProjects = await axios
        .post(`${ADDR}/getProjectsOfAUser`, { uid })
        .then((response) => JSON.parse(response.data[0]?.projects || "[]"));

      existingProjects.push({
        projectName,
        usernameList: [...new Set(usernameList)],
        createdDate,
        lastModified,
        lastUsed,
      });

      const success = await axios.post(`${ADDR}/addProjectToProjectList`, {
        existingProjects,
        uid,
      });
      if (success.status === 200) {
        dispatch(
          setCurrentSelectedProject({
            projectName,
            projectList: usernameList,
          }),
        );
        navigate("/home", {
          state: { projectName, projectList: usernameList },
        });
      }
    } catch (error) {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Failed to create project.",
        }),
      );
    } finally {
      dispatch(setLoaderModalStatus({ status: false, message: "" }));
    }
  };

  const renderUsernameList = () =>
    usernameList.map((username, index) => (
      <div
        key={index}
        className="flex mx-1 my-1 bg-lightbgtwo dark:bg-darkbgfour rounded-[12px] w-fit"
      >
        <p className="my-1 ml-2 dark:text-lightbgtwo">
          {"@"}
          {username}
        </p>
        <button
          onClick={() => removeUsernameHandler(username)}
          className="cursor-pointer rounded-full w-[16px] h-[16px] my-2 mx-2 flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-[16px] h-[16px] dark:text-white text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    ));

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl p-6 text-left shadow-xl transition-all">
                  <div className="flex justify-between">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-2"
                    >
                      Add New Project
                    </Dialog.Title>
                    <button
                      onClick={closeModal}
                      class="cursor-pointer rounded-full bg-grey dark:bg-lightbgtwo w-10 h-10 my-2 mx-4 flex justify-center items-center "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="w-6 h-6 dark:text-black text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="flex flex-col mt-2">
                    <label className=" text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Project Name
                    </label>
                    <input
                      placeholder="Enter Project Name"
                      value={projectName}
                      onChange={handleProjectNameChange}
                      className="w-full rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                    />

                    <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                      Instagram Username
                    </label>
                    <div className="flex">
                      <input
                        placeholder="insightstool"
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                        className="w-5/6 rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                      />
                      <button
                        onClick={addUsernameHandler}
                        class="cursor-pointer rounded-full bg-lightbgtwo dark:bg-darkbgthree w-10 h-10 my-2 mx-4 flex justify-center items-center "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-6 h-6 dark:text-white text-black"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="border-t w-full mt-2"></div>

                    <div className="mt-4 w-full h-40 overflow-y-auto rounded-lg">
                      {usernameList.length ? (
                        <div className="grid grid-cols-2">
                          {renderUsernameList()}
                        </div>
                      ) : (
                        <p className="text-grey ml-2">
                          No usernames added yet.
                        </p>
                      )}
                    </div>

                    <button
                      onClick={createProjectInDB}
                      className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
                    >
                      Add Project
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <LoaderModal />
      <ErrorModal />
    </>
  );
};

export default CreateProjectModal;
