import { useEffect, useState } from "react";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../app/firebase";
import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { ReactComponent as LoginIcon } from "../../assets/login.svg";
import { LoaderModal } from "../Modals/LoaderModal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setErrorModalStatus,
  setLoaderModalStatus,
} from "../Modals/ModalSlice";
import { ErrorModal } from "../Modals/ErrorModal";
import ImagePicker from "./ImagePicker/ImagePicker";

export default function Register() {
  const [email, setEmail] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });
  const [password, setPassword] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });

  const [password1, setPassword1] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });

  const [firstName, setFirstName] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });
  const [lastName, setLastName] = useState({
    value: "",
    isValid: false,
    hasChanged: false,
    feedback: "",
  });

  let displayImage = useAppSelector(
    (state) => state.modalSlice.displayImageUser.image,
  );

  const [isValid, setIsValid] = useState(false);
  const [user, loading] = useAuthState(auth);
  //   const router = useRouter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    let confirm_password = { ...password1 };

    //Validation
    if (confirm_password.value !== password.value && password1.isValid) {
      confirm_password.isValid = false;
      confirm_password.feedback = "Confirm Password must match above password";
      setPassword1(confirm_password);
      return;
    }
    if (confirm_password.value === password.value && !password1.isValid) {
      confirm_password.isValid = true;
      confirm_password.valFeedback = "";
      setPassword1(confirm_password);
      return;
    }
  }, [password1, password]);

  useEffect(() => {
    if (loading) {
      //show loader
    }
    if (user) {
      // router.replace("/dashboard");
      // window.location.replace("/login");
    }
  }, [user, loading]);

  const loginToFirebase = async () => {
    if (
      email.value === "" &&
      password.value === "" &&
      password1.value === "" &&
      firstName.value === "" &&
      lastName.value === ""
    ) {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Enter Incomplete Info",
        }),
      );

      return;
    }

    // if (displayImage === null) {
    //   dispatch(
    //     setErrorModalStatus({
    //       status: true,
    //       message: "Please Upload A Display Picture",
    //     }),
    //   );

    //   return;
    // }

    if (!isValid) {
      console.log("RETURRNING");
      return;
    }

    dispatch(
      setLoaderModalStatus({
        status: true,
        message: "Signing Up",
      }),
    );
    let fullname = firstName.value + " " + lastName.value;
    let status = await registerWithEmailAndPassword(
      displayImage,
      fullname,
      email.value,
      password.value,
    );

    console.log("ST:", status);
    if (status === true) {
      window.location.replace("/login");
    } else {
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "Signing Up",
        }),
      );
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Contact Admin",
        }),
      );
    }
  };

  const goToLogin = () => {
    window.location.replace("/login");
  };

  useEffect(() => {
    let formValid =
      email.isValid &&
      password.isValid &&
      firstName.isValid &&
      lastName.isValid &&
      password1.isValid;
    setIsValid(formValid);
  }, [email, password, firstName, lastName, password1]);

  const emailHandler = (event) => {
    let email_temp = { ...email };
    email_temp.value = event.target.value;
    email_temp.isValid = true;
    email_temp.hasChanged = true;
    email_temp.feedback = "";
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email_temp.value.search(re) === -1) {
      email_temp.isValid = false;
      email_temp.feedback = "Enter a valid email address";
    }
    setEmail(email_temp);
  };

  const passwordHandler = (event) => {
    let password_inp = { ...password };
    password_inp.value = event.target.value;
    password_inp.hasChanged = true;
    password_inp.isValid = true;
    password_inp.feedback = "";
    if (event.target.value.toString().length === 0) {
      password_inp.isValid = false;
      password_inp.feedback = "Please enter a password";
    }
    //NO validation required Here
    setPassword(password_inp);
  };

  const passwordHandler1 = (event) => {
    let password_inp = { ...password1 };
    password_inp.value = event.target.value;
    password_inp.hasChanged = true;
    password_inp.isValid = true;
    password_inp.feedback = "";
    if (event.target.value.toString().length === 0) {
      password_inp.isValid = false;
      password_inp.feedback = "Please enter a password";
    }
    //NO validation required Here
    setPassword1(password_inp);
  };

  const firstNameHandler = (event) => {
    let first_name = { ...firstName };
    first_name.value = event.target.value.trim();
    first_name.hasChanged = true;
    first_name.isValid = true;
    first_name.feedback = "";
    //Validation
    const re = /^[A-Za-z]+$/;
    if (first_name.value.search(re) === -1) {
      first_name.isValid = false;
      first_name.feedback = "Special characters not allowed in first name";
    }
    if (first_name.value.length === 0) {
      first_name.isValid = false;
      first_name.feedback = "Enter First Name";
    }

    setFirstName(first_name);
  };

  const lastNameHandler = (event) => {
    let last_name = { ...lastName };
    last_name.value = event.target.value.trim();
    last_name.hasChanged = true;
    last_name.isValid = true;
    last_name.feedback = "";

    //Validation
    const re = /^[A-Za-z]+$/;
    if (last_name.value.search(re) === -1) {
      last_name.isValid = false;
      last_name.feedback = "Special characters not allowed in last name";
    }
    if (last_name.value.length === 0) {
      last_name.isValid = false;
      last_name.feedback = "Enter Last Name";
    }
    setLastName(last_name);
  };

  return (
    <>
      {/* <Nav /> */}
      <div className="flex justify-center  animate-fade-in-down">
        <div className="flex flex-col mt-20 w-[400px] h-[800px]">
          <div className="">
            <h1 className="text-3xl text-left font-semibold text-darkbgone dark:text-lightbgone py-5">
              Sign Up
            </h1>

            <div>
              <ImagePicker />
            </div>

            <div className="flex flex-row">
              <div className="text-left">
                <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                  First Name{" "}
                </label>
                <input
                  onChange={firstNameHandler}
                  value={firstName.value}
                  type="text"
                  className="w-full text-darkbgone rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                  placeholder={"First Name"}
                />
              </div>
              <span className="mx-2"></span>
              <div className="text-left">
                <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                  Last Name{" "}
                </label>

                <input
                  onChange={lastNameHandler}
                  value={lastName.value}
                  type="text"
                  className="w-full text-darkbgone rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
                  placeholder={"Last Name"}
                />
              </div>
            </div>
            <p className="text-center text-sm text-rose-700">
              {firstName.feedback}
            </p>
            <p className="text-center text-sm text-rose-700">
              {lastName.feedback}
            </p>

            <div className="flex justify-left">
              <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                Enter Email{" "}
              </label>
            </div>

            <input
              onChange={emailHandler}
              value={email.value}
              type="text"
              className="w-full text-darkbgone rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
              placeholder={"user@organisation.com"}
            />
            <p className="text-center text-sm text-rose-700">
              {email.feedback}
            </p>

            <div className="flex justify-left">
              <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                Enter Password{" "}
              </label>
            </div>

            <input
              onChange={passwordHandler}
              value={password.value}
              type="password"
              className="w-full text-darkbgone rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
              placeholder={"Type Password"}
            />
            <p className="text-center text-sm text-rose-700">
              {password.feedback}
            </p>

            <div className="flex justify-left">
              <label className="text-darkbgone dark:text-lightbgtwo font-semibold text-left">
                Confirm Password{" "}
              </label>
            </div>
            <input
              onChange={passwordHandler1}
              value={password1.value}
              type="password"
              className="w-full text-darkbgone rounded-[12px] text-left pl-4 py-2 my-2 font-medium"
              placeholder={"Retype Password"}
            />
            <p className="text-center text-sm text-rose-700">
              {password1.feedback}
            </p>

            <div className="flex justify-center mt-4">
              <button
                // disabled={!isValid}
                onClick={loginToFirebase}
                className="w-full cursor-pointer my-2 text-lightbgtwo bg-primary font-semibold rounded-[20px] text-lg px-5 py-4 text-center mr-3 md:mr-0 flex justify-center items-center"
              >
                Register
                {/* <LoginIcon width={24} height={20} /> */}
              </button>
            </div>

            <div>
              <p className="text-center text-lg font-normal  text-darkbgone dark:text-lightbgone  py-8">
                Already have an account?{" "}
                <Link
                  onClick={goToLogin}
                  className="underline decoration-twitterblue decoration-solid decoration-2 underline-offset-4 hover:text-twitterblue hover:decoration-white font-bold"
                >
                  Login
                </Link>{" "}
                now
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <ErrorModal />
      <LoaderModal />
    </>
  );
}
