//Offline Config

// export const PROTOCOL = "http";
// export const IP = "192.168.1.138";
// export const PORT = "3010";
// export const ADDR = PROTOCOL + "://" + IP + ":" + PORT;

//Online Config

export const PROTOCOL = "https";
export const IP = "unbubblebackend.softwareosity.com";
export const PORT = "3010";
export const ADDR = PROTOCOL + "://" + IP + ":" + PORT;
