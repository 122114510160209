import React, { useState } from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";

function FacebookLoginComponent() {
  const [userData, setUserData] = useState(null);
  const [accessToken, setAccessToken] = useState(null); // State to store the access token

  // This function will be triggered when the user logs in
  const responseFacebook = (response) => {
    console.log(response); // Logs the full response object from Facebook

    if (response.accessToken) {
      setAccessToken(response.accessToken); // Save the access token
      console.log("Acess Token: ", response.accessToken);
      setUserData({
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
      });
    }
  };

  return (
    <div>
      {!userData ? (
        <FacebookLogin
          appId="1787751435084627" // Replace with your Facebook App ID
          autoLoad={false} // Whether to auto-load the login dialog
          fields="name,email,picture" // Fields you want to retrieve
          callback={responseFacebook} // Callback after successful login
          textButton="Login with Facebook" // Customize the button text
          cssClass="facebook-login-btn" // Optional CSS class for the button
        />
      ) : (
        <div>
          <h2>Welcome, {userData.name}</h2>
          <img src={userData.picture} alt="Profile" />
          <p>Email: {userData.email}</p>
          <p>Access Token: {accessToken}</p> {/* Display the access token */}
        </div>
      )}
    </div>
  );
}

export default FacebookLoginComponent;
