import InstagramLogo from "../../assets/instagram.jpeg";

const { createSlice } = require("@reduxjs/toolkit");

// const oneYearAgo = new Date();
// oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
// const oneYearAgoTimestamp = Math.floor(oneYearAgo.getTime() / 1000);

// const oneMonthAgo = new Date();
// oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 2);
// const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);

// const now = new Date();
// const nowTimestamp = Math.floor(now.getTime() / 1000);

// var curra = new Date();
// curra.setMonth(curra.getMonth() - 1);
// // var sinceDate = curra.toISOString().substring(0,10);
// var sinceDate = Math.floor(curra.getTime() / 1000);

// var curr = new Date();
// // curr.setDate(curr.getDate() + 3);
// curr.setMonth(curr.getMonth());
// var untilDate = Math.floor(curr.getTime() / 1000);

var curra = new Date();
curra.setDate(curra.getDate() - 7); // Subtract 7 days for one week ago
var sinceDate = Math.floor(curra.getTime() / 1000);

var curr = new Date();
curr.setMonth(curr.getMonth()); // Keeps the current date unchanged
var untilDate = Math.floor(curr.getTime() / 1000);

const initialState = {
  profileInfoModal: {
    status: false,
    isClickable: false,
    profile_picture_url: "" || InstagramLogo,
    name: "" || "Instagram",
    username: "" || "instagram",
    id: "" || "17841400039600391",
    followersCount: "" || "676031777",
    followsCount: "" || "119",
    website: "" || "help.instagram.com",
    biography: "" || "Discover what's new on Instagram 🔎✨",
    totalMediaCount: "" || "7764",
  },
  currentProfileInfoDataIndex: 0,
  multipleProfileInfoData: [],
  createProjectModal: {
    status: false,
    projectName: "",
    createdDate: new Date().toDateString(),
    lastUsed: new Date().toDateString(),
    lastModified: new Date().toDateString(),
    usernames: [],
  },

  addUserToTeamModal: {
    status: false,
    name: "",
    role: "",
    email: "",
    createdDate: new Date().toDateString(),
  },

  editProjectModal: {
    status: false,
    projectName: "",
    createdDate: new Date().toDateString(),
    lastUsed: new Date().toDateString(),
    lastModified: new Date().toDateString(),
    usernames: [],
  },
  requestSettingsModal: {
    status: false,
  },
  requestSettingUntilDate: {
    untilDate: untilDate,
  },
  requestSettingSinceDate: {
    sinceDate: sinceDate,
  },
  requestSettingDateFilterStatus: {
    status: true,
  },
  useListStatus: {
    status: true,
  },
  loaderModalStatus: {
    status: false,
    message: "",
  },
  errorModalStatus: {
    status: false,
    message: "",
  },

  UserUnverifiedModal: {
    status: false,
    message: "",
  },

  planExpiredModal: {
    status: false,
    message: "",
  },

  deleteConfirmationModal: {
    status: false,
    projectName: "",
    entryUID: "",
    entryItem: "",
  },

  deleteTeamUserConfirmationModal: {
    status: false,
    AddedByEmail: "",
    AddedByUserID: "",
    Email: "",
    Name: "",
    Role: "",
  },

  useSingleProfile: {
    status: false,
  },
  displayImageUser: {
    image: null,
  },

  paymentModal: {
    status: false,
    planType: "standard",
  },

  searchModal: {
    status: false,
    data: [],
  },

  searchModalDataBackup: {
    data: [],
  },

  paymentScreenshot: {
    image: null,
  },

  cssForProjects: "",
  cssForSettings: "",
};

const ModalSlice = createSlice({
  name: "modalSlice",
  initialState,
  reducers: {
    setProfileInfoModalStatus: (state, action) => {
      state.profileInfoModal = action.payload;
    },

    setRequestSettingsModal: (state, action) => {
      state.requestSettingsModal = action.payload;
    },
    setRequestSettingUntilDate: (state, action) => {
      state.requestSettingUntilDate = action.payload;
    },
    setRequestSettingSinceDate: (state, action) => {
      state.requestSettingSinceDate = action.payload;
    },
    setRequestSettingDateFilterStatus: (state, action) => {
      state.requestSettingDateFilterStatus = action.payload;
    },
    setLoaderModalStatus: (state, action) => {
      state.loaderModalStatus = action.payload;
    },
    setErrorModalStatus: (state, action) => {
      state.errorModalStatus = action.payload;
    },
    setCreateProjectModal: (state, action) => {
      state.createProjectModal = action.payload;
    },
    setUseListStatus: (state, action) => {
      state.useListStatus = action.payload;
    },
    setEditProjectModal: (state, action) => {
      state.editProjectModal = action.payload;
    },
    setDeleteConfirmationStatus: (state, action) => {
      state.deleteConfirmationModal = action.payload;
    },
    setMultipleProfileInfoData: (state, action) => {
      state.multipleProfileInfoData = action.payload;
    },
    setCurrentProfileInfoDataIndex: (state, action) => {
      state.currentProfileInfoDataIndex = action.payload;
    },
    setUseSingleProfile: (state, action) => {
      state.useSingleProfile = action.payload;
    },
    setUserDisplayImage: (state, action) => {
      state.displayImageUser = action.payload;
    },
    setPaymentModal: (state, action) => {
      state.paymentModal = action.payload;
    },
    setPaymentScreenshot: (state, action) => {
      state.paymentScreenshot = action.payload;
    },
    setAddUserToTeamModal: (state, action) => {
      state.addUserToTeamModal = action.payload;
    },

    setUserUnverifiedModal: (state, action) => {
      state.UserUnverifiedModal = action.payload;
    },
    setCSSForProjects: (state, action) => {
      state.cssForProjects = action.payload;
    },
    setCSSForSettings: (state, action) => {
      state.cssForSettings = action.payload;
    },

    setPlanExpiredModal: (state, action) => {
      state.planExpiredModal = action.payload;
    },
    setSearchModal: (state, action) => {
      state.searchModal = action.payload;
    },
    setSearchModalDataBackup: (state, action) => {
      state.searchModalDataBackup = action.payload;
    },
  },
});

export default ModalSlice.reducer;
export const {
  setProfileInfoModalStatus,
  setRequestSettingsModal,
  setRequestSettingSinceDate,
  setRequestSettingUntilDate,
  setRequestSettingDateFilterStatus,
  setLoaderModalStatus,
  setErrorModalStatus,
  setCreateProjectModal,
  setUseListStatus,
  setEditProjectModal,
  setDeleteConfirmationStatus,
  setMultipleProfileInfoData,
  setCurrentProfileInfoDataIndex,
  setUseSingleProfile,
  setUserDisplayImage,
  setPaymentModal,
  setPaymentScreenshot,
  setAddUserToTeamModal,
  setUserUnverifiedModal,
  setCSSForProjects,
  setCSSForSettings,
  setPlanExpiredModal,
  setSearchModal,
  setSearchModalDataBackup,
} = ModalSlice.actions;
