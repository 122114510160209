import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingIcons from "react-loading-icons";
import { ReactComponent as ErrorSVG } from "../../assets/error.svg";
import { setErrorModalStatus } from "./ModalSlice";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../app/firebase";
export const UserUnverifiedModal = () => {
  const dispatch = useDispatch();

  let isOpen = useSelector(
    (state) => state.modalSlice.UserUnverifiedModal.status,
  );
  let msg = useSelector(
    (state) => state.modalSlice.UserUnverifiedModal.message,
  );
  const [user] = useAuthState(auth);

  const closeModal = () => {};

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-lightbgone dark:bg-darkbgtwo opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-instagram-dark backdrop-blur-xl  opacity-100  p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-white"
                  ></Dialog.Title>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <div className="w-24 h-24">
                      <ErrorSVG />
                    </div>
                    <br />

                    <p className="text-2xl text-center font-semibold text-darkbgone dark:text-lightbgone">
                      {msg}
                    </p>

                    {user !== null && (
                      <p className="text-xl text-center font-semibold text-darkbgone dark:text-lightbgone">
                        {user.email}
                      </p>
                    )}

                    <br />

                    <p className="text-2xl text-center font-semibold text-darkbgone dark:text-lightbgone">
                      {"contact: email@email.com"}
                    </p>
                    {/* <p className="text-2xl text-center text-white font-bold">
Network Error                    </p> */}
                    <br />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
