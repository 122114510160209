import { ReactComponent as VectorSvg } from "../../assets/v2/Vector.svg";
import { ReactComponent as Ellipse } from "../../assets/v2/Ellipse.svg";
import { ReactComponent as Hiphen } from "../../assets/v2/hiphen.svg";
import { ReactComponent as Search } from "../../assets/v2/search.svg";
import dp from "../../assets/v2/profile.png";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  setCommentsSelected,
  setCurrentSelectedProject,
  setDateSelected,
  setEngagementSelected,
  setIsDescending,
  setIsDescendingComments,
  setIsEngagement,
  setIsLatest,
  setIsReach,
  setIsUsername,
  setLikesSelected,
  setMediaBackupForUsernameList,
  setMediaData,
  setMediaDataBackup,
  setMediaTypeSelected,
  setMultipleProfileData,
  setNewMediaData,
  setReachSelected,
  setSelectedUsernameList,
  setUsernameSelected,
} from "./HomeSlice";
import axios from "axios";
import { ADDR } from "../../config/config";
import { useSelector } from "react-redux";
import {
  setEditProjectModal,
  setErrorModalStatus,
  setLoaderModalStatus,
  setProfileInfoModalStatus,
  setRequestSettingSinceDate,
  setRequestSettingUntilDate,
  setSearchModal,
  setSearchModalDataBackup,
} from "../Modals/ModalSlice";
import { Card } from "flowbite-react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../app/firebase";
import { ErrorModal } from "../Modals/ErrorModal";
import LoadingIcons from "react-loading-icons";
import SearchCaptionModal from "../Modals/SearchCaptionsModal";
import EditProjectModal from "../Modals/EditProjectModal";
import { useNavigate } from "react-router-dom";
import FacebookLoginComponent from "../FacebookLoginComponent/FacebookLoginComponent";

//env update - 11 jan

const NewHome = () => {
  const dispatch = useAppDispatch();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  let isDescending = useAppSelector((state) => state.homeSlice.isDescending);
  let isDescendingComments = useAppSelector(
    (state) => state.homeSlice.isDescendingComments,
  );
  let isLatest = useAppSelector((state) => state.homeSlice.isLatest);
  let isEngagement = useAppSelector((state) => state.homeSlice.isEngagement);
  let isReach = useAppSelector((state) => state.homeSlice.isReach);
  let isUsername = useAppSelector((state) => state.homeSlice.isUsername);

  let accessToken = process.env.REACT_APP_ACCESS_TOKEN;

  const location = useLocation();
  const { projectName, projectList } = location.state || {};

  let multipleProfileData = useAppSelector(
    (state) => state.homeSlice.multipleProfileData,
  );

  const sinceDate = useSelector(
    (state) => state.modalSlice.requestSettingSinceDate.sinceDate,
  );
  const untilDate = useSelector(
    (state) => state.modalSlice.requestSettingUntilDate.untilDate,
  );

  const selectedUsernameList = useSelector(
    (state) => state.homeSlice.selectedUsernameList,
  );

  const selectedUsernameListBackup = useSelector(
    (state) => state.homeSlice.selectedUsernameListBackup,
  );

  let profileInfoModal = useAppSelector(
    (state) => state.modalSlice.profileInfoModal,
  );

  let mediaData = useAppSelector((state) => state.homeSlice.mediaData);
  let newMediaData = useAppSelector((state) => state.homeSlice.newMediaData);

  let mediaDataBackup = useAppSelector(
    (state) => state.homeSlice.mediaDataBackup,
  );
  let mediaDataBackupForUsernameList = useAppSelector(
    (state) => state.homeSlice.mediaDataBackupForUsernameList,
  );

  const [tempSinceDate, setTempSinceDate] = useState(
    new Date(sinceDate * 1000),
  );
  const [tempUntilDate, setTempUntilDate] = useState(
    new Date(untilDate * 1000),
  );

  let likesSelected = useAppSelector((state) => state.homeSlice.likesSelected);
  let commentsSelected = useAppSelector(
    (state) => state.homeSlice.commentsSelected,
  );
  let dateSelected = useAppSelector((state) => state.homeSlice.dateSelected);
  let engagementSelected = useAppSelector(
    (state) => state.homeSlice.engagementSelected,
  );

  let reachSelectedSelected = useAppSelector(
    (state) => state.homeSlice.reachSelectedSelected,
  );

  let usernameSelected = useAppSelector(
    (state) => state.homeSlice.usernameSelected,
  );

  let mediaTypeSelected = useAppSelector(
    (state) => state.homeSlice.mediaTypeSelected,
  );

  const [filterLabel, setFilterLabel] = useState("Media Type");

  const [likeLabelBold, setLikeLabelBold] = useState("");

  const openSearchCaptionModal = () => {
    console.log("Open");

    if (newMediaData.length !== 0) {
      dispatch(
        setSearchModal({
          status: true,
          data: [...newMediaData],
        }),
      );

      dispatch(
        setSearchModalDataBackup({
          data: [...newMediaData],
        }),
      );
    } else {
      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Retrieve Posts Before Searching",
        }),
      );
    }
  };

  const getAPIWithListsAllUsernames = async () => {
    try {
      dispatch(
        setLoaderModalStatus({
          status: true,
          message: "Fetching Data From Instagram",
        }),
      );

      let allUsernames = selectedUsernameList;
      let newResponseArrayMedia = [];
      let multipleProfileData = [];
      console.log(allUsernames);

      newResponseArrayMedia = await Promise.all(
        allUsernames.map(async (item, index) => {
          let url = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${item}){profile_picture_url,name,followers_count,follows_count,website,biography,media_count,media.since(${sinceDate}).until(${untilDate}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;

          console.log("URL: ", url);

          try {
            const response = await axios.get(url);
            console.log("just after req:", response);

            let mediaInfo = [];
            let name = response.data.business_discovery.name;
            let profile_picture_url =
              response.data.business_discovery.profile_picture_url;
            let followersCount =
              response.data.business_discovery.followers_count;
            let followsCount = response.data.business_discovery.follows_count;
            let website = response.data.business_discovery.website;
            let biography = response.data.business_discovery.biography;
            let id = response.data.business_discovery.id;
            let totalMediaCount = response.data.business_discovery.media_count;

            console.log("FIRST RESPONSE: ", response);

            let profile = {
              status: false,
              isClickable: true,
              profile_picture_url: profile_picture_url,
              name: name,
              username: item,
              id: id,
              followersCount: followersCount,
              followsCount: followsCount,
              website: website,
              biography: biography,
              totalMediaCount: totalMediaCount,
            };

            //Store profile data here
            await axios
              .post(ADDR + "/storeProfileData", {
                status: false,
                isClickable: true,
                profile_picture_url: profile_picture_url,
                name: name,
                username: item,
                id: id,
                followersCount: followersCount,
                followsCount: followsCount,
                website: website,
                biography: biography,
                totalMediaCount: totalMediaCount,
              })
              .then((response) => {
                console.log(response);
              });

            multipleProfileData.push(profile);
            dispatch(setProfileInfoModalStatus(profile));

            if (response.data.business_discovery.media === undefined) {
              if (allUsernames.length < 1) {
                dispatch(
                  setErrorModalStatus({
                    status: true,
                    message:
                      "No Data Retrieved - Try with Other Profile / Time Duration",
                  }),
                );
              }
              dispatch(
                setLoaderModalStatus({
                  status: false,
                  message: "Fetching Data From Instagram",
                }),
              );
              dispatch(setMediaData([]));
              return;
            }

            if (response.data.business_discovery.media.data === undefined) {
              dispatch(
                setLoaderModalStatus({
                  status: false,
                  message: "",
                }),
              );
              return;
            }

            mediaInfo.push(...response.data.business_discovery.media.data);
            let afterKey =
              response.data.business_discovery.media.paging?.cursors?.after ||
              null;

            while (afterKey !== null) {
              console.log("Looping");
              let newURL = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${item}){media.since(${sinceDate}).until(${untilDate}).after(${afterKey}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;

              const nextResponse = await axios.get(newURL);
              console.log(nextResponse);
              mediaInfo.push(
                ...nextResponse.data.business_discovery.media.data,
              );

              afterKey =
                nextResponse.data.business_discovery.media.paging?.cursors
                  ?.after || null;
            }

            dispatch(
              setLoaderModalStatus({
                status: false,
                message: "",
              }),
            );

            let localData = {
              username: item,
              mediaData: [...mediaInfo],
              lastSinceDate: sinceDate,
              lastUntilDate: untilDate,
            };

            localStorage.setItem("localData", JSON.stringify(localData));

            return mediaInfo;
          } catch (error) {
            console.error(`Error fetching data for ${item}:`, error);
            if (
              error.response.data.error !== undefined &&
              error.response.data.error.code === 110
            ) {
              dispatch(
                setLoaderModalStatus({
                  status: false,
                  message: "",
                }),
              );

              dispatch(
                setErrorModalStatus({
                  status: true,
                  message:
                    "Not A Business Profile : " +
                      error.response.data.error.error_user_msg ||
                    "Not A Valid Profile",
                }),
              );
              return;
            }

            dispatch(
              setLoaderModalStatus({
                status: false,
                message: "",
              }),
            );

            dispatch(
              setErrorModalStatus({
                status: true,
                message: "Something Went Wrong",
              }),
            );

            console.error("Error fetching data: ", error);

            return []; // Return empty array on error to avoid breaking Promise.all
          }
        }),
      );
      //END OF LOOP HERE

      //Add profile info data
      //Not needed I guess
      //   dispatch(setMultipleProfileInfoData([...multipleProfileData]));

      console.log("All media info:", newResponseArrayMedia);
      //Flatten the array / combine arrays
      let flattenedArray = newResponseArrayMedia.flat().filter(Boolean);
      console.log("FA:", flattenedArray);

      //   dispatch(setMediaData([...flattenedArray]));
      //   dispatch(setMediaDataBackup([...flattenedArray]));

      //Storing in backend to retrieve later
      let mediaData = [...flattenedArray];

      await axios
        .post(ADDR + "/storeInstagramPosts", { mediaData })
        .then((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updatePostsInDB = async () => {
    //This function will update posts in DB with all latest values from insta /meta servers
    //We can add this call from backend or frontend - need to think what works best. ( I THINK CALLING FROM FRONTEND WILL WORK BEST FOR NOW)

    console.log("MPM");
    getAPIWithListsAllUsernames();
  };

  let getPostsFromDB = async (sinceDate, untilDate, username) => {
    console.log(
      "IN GETPOSTSFROMDB - ITS RETURNING EMPTY / NOT RETURNING EMPTY ANYMORE",
    );
    console.log("Since Date: ", sinceDate);
    console.log("Until Date: ", untilDate);
    console.log("UserName: ", username);

    let response = await axios
      .post(ADDR + "/getPostForSingleProfile", {
        username: username,
        sinceDate: sinceDate,
        untilDate: untilDate,
      })
      .then(async (response) => {
        console.log("In getPostsFromDB:", response.data);

        if (response.data.length > 0) {
          console.log("returning the array");
          return [...response.data];
        } else {
          console.log("No posts received from DB");
          return "no posts";
        }
      });
    console.log("RESGETPOSTSFROMDB: ", response);
    return response;
  };

  const callSingleProfileForMissingPosts = async (sDate, uDate, user) => {
    // Build a new request
    try {
      console.log("in CSPFMP function");
      let url = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${user}){profile_picture_url,name,followers_count,follows_count,website,biography,media_count,media.since(${sDate}).until(${uDate}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;

      return axios
        .get(url)
        .then(async (response) => {
          if (response.data.business_discovery.media === undefined) {
            return null; // Return null if no media is found
          }

          console.log(response);

          let mediaInfo = [];
          mediaInfo.push(...response.data.business_discovery.media.data);

          let afterKey = null;

          if (response.data.business_discovery.media.paging !== undefined) {
            if (
              response.data.business_discovery.media.paging.cursors !==
                undefined &&
              response.data.business_discovery.media.paging.cursors.after !==
                undefined
            ) {
              afterKey =
                response.data.business_discovery.media.paging.cursors.after;
            }
          }

          // Loop through paginated data
          while (afterKey !== null) {
            console.log("Looping");
            let newURL = `https://graph.facebook.com/v20.0/17841453017373357?fields=business_discovery.username(${user}){media.since(${sDate}).until(${uDate}).after(${afterKey}){comments_count,like_count,media_type,permalink,id,caption,timestamp,username}}&access_token=${accessToken}`;
            console.log("URL: ", newURL);

            const nextResponse = await axios.get(newURL);
            console.log(nextResponse);

            mediaInfo.push(...nextResponse.data.business_discovery.media.data);

            if (
              nextResponse.data.business_discovery.media.paging &&
              nextResponse.data.business_discovery.media.paging.cursors &&
              nextResponse.data.business_discovery.media.paging.cursors.after
            ) {
              afterKey =
                nextResponse.data.business_discovery.media.paging.cursors.after;
            } else {
              afterKey = null;
            }
          }

          console.log("IN POST:", mediaInfo);

          return mediaInfo; // Return the concatenated mediaInfo array
        })
        .catch((e) => {
          console.log(e);
          console.log("ERRORE");

          setLoader(false);

          dispatch(
            setLoaderModalStatus({
              status: false,
              message: "",
            }),
          );
        });
    } catch (e) {
      console.log(e);
      console.log("ERROR");
      setLoader(false);
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );
    }
  };

  //Write a separate function to pre-fetch posts only if they exist in db
  //And if they dont exist in db - request for short time duration from api

  const preFetchPostsFromDB = async (userList) => {};

  //Updating the below function - Nov'23 - 2024 - 3:08 pm
  const getMultipleProfilePosts = async (userList) => {
    //Setting filter to default
    setFilterLabel("Media Type");
    //Emptying existing data
    // dispatch(setMediaData([]));

    try {
      // dispatch(
      //   setLoaderModalStatus({
      //     status: true,
      //     message: "Fetching Data From Instagram",
      //   }),
      // );

      console.log("GG:", selectedUsernameList);

      let allUsernames = selectedUsernameList;

      if (selectedUsernameList.length === 0) {
        allUsernames = userList;
      }

      let newResponseArrayMedia = [];
      let multipleProfileData = [];
      console.log(allUsernames);

      if (allUsernames.length < 1) {
        console.log("NO USERS SELECTED");
        dispatch(
          setErrorModalStatus({
            status: true,
            message: "Select Accounts",
          }),
        );

        setLoader(false);
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "",
          }),
        );

        return;
      }

      setLoader(true);
      newResponseArrayMedia = await Promise.all(
        allUsernames.map(async (item, index) => {
          console.log(item);
          await axios
            .post(ADDR + "/checkProfileInDB", { username: item })
            .then(async (response) => {
              console.log(response);
              //If profile exists
              if (response.data.length > 0) {
                console.log("PROFILE DATA FROM BACKEND");
                console.log(response.data[0]);
                let profileData = response.data[0];
                let profile = {
                  status: profileData.status,
                  isClickable: profileData.isClickable,
                  profile_picture_url: profileData.profile_picture_url,
                  name: profileData.name,
                  username: profileData.username,
                  id: profileData.id,
                  followersCount: profileData.followersCount,
                  followsCount: profileData.followsCount,
                  website: profileData.website,
                  biography: profileData.biography,
                  totalMediaCount: profileData.totalMediaCount,
                };

                //Commenting below line - not needed i guess
                dispatch(setProfileInfoModalStatus(profile));

                //Search for posts in DB ?

                //Using single profile endpoint for now : optimisations in future for perfomance - currently number of calls to backend will increase.
                await axios
                  .post(ADDR + "/getPostForSingleProfile", {
                    username: item,
                    sinceDate: sinceDate,
                    untilDate: untilDate,
                  })
                  .then(async (response) => {
                    console.log(response);
                    let posts = response.data;
                    console.log(posts);

                    if (posts.length === 0) {
                      //If posts are not in db - it should update in db and set - now its updating but not setting.
                      console.log(
                        "POSTS DONT EXIST IN DB - updating and setting",
                      );

                      let updateResponse = await updatePostsInDB().then(
                        async () => {
                          console.log(
                            "response after update posts in SPM: ",
                            response,
                          );

                          if (response === true) {
                            let test = await getPostsFromDB(
                              sinceDate,
                              untilDate,
                              item,
                            ).then((response) => {
                              console.log(
                                "AFTER GETTING POSTS FROM DB:",
                                response,
                              );
                              multipleProfileData.push(response);

                              // return response;

                              //NOV18: Got posts on 1:29PM / NOV 18 - see how to set
                            });

                            //I think if posts dont exists in DB set them and return;

                            return;
                          } else {
                            //Do nothing
                          }
                        },
                      );
                    }

                    //Sort posts by date here
                    // Sort posts by timestamp
                    await posts.sort(
                      (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
                    );

                    // Ascending order
                    console.log("SORTED ARR:", posts);
                    //Now extract dates - oldest and newest of posts from db post

                    if (posts.length === 0) {
                      dispatch(
                        setErrorModalStatus({
                          status: true,
                          message:
                            "Posts not retrieved for profile " +
                            item +
                            "due to meta policy.",
                        }),
                      );
                      return;
                    }

                    // Extract oldest and newest dates
                    const oldestPost = posts[0].timestamp;
                    const newestPost = posts[posts.length - 1].timestamp;

                    console.log(oldestPost);
                    console.log(newestPost);

                    const oldestTimestamp = Math.floor(
                      new Date(
                        new Date(oldestPost.replace("+0000", "Z")).setUTCHours(
                          0,
                          0,
                          0,
                          0,
                        ),
                      ).getTime() / 1000,
                    );
                    const newestTimestamp = Math.floor(
                      new Date(
                        new Date(newestPost.replace("+0000", "Z")).setUTCHours(
                          0,
                          0,
                          0,
                          0,
                        ),
                      ).getTime() / 1000,
                    );

                    //Compare dates of since and until with oldest and newest , individually.

                    let sDate = sinceDate;
                    let uDate = untilDate;

                    console.log("SINCE DATE:", sDate);
                    console.log("Oldest Date:", oldestTimestamp);
                    console.log("Newest Date:", newestTimestamp);
                    console.log("UNTIL DATE:", uDate);

                    //Now compare this timestamps

                    //OLDEST DATE FROM DB AND CHECKING WITH CURRENT SET OLDEST SINCE DATE
                    //NEED TO TEST THIS BLOCK - NOT SEEM TO BE WORKING AS INTENDED- WORKING NOW : NEED TO FIGURE OUT SETTING WITH CURRENT//but still dont know the request its making
                    let newPosts;
                    let firstStoreReq;
                    let secondStoreReq;

                    if (sDate < oldestTimestamp && newestTimestamp < uDate) {
                      //Same racecondition issue here: Posts are being stored but not displayed correctly!

                      console.log(
                        "BOTH SINCE DATE LESS THAN OLD TS AND NEWESTDATE LESS THAN UNTIL DATE",
                      );
                      dispatch(setMediaData([]));
                      dispatch(setMediaDataBackup([]));
                      newPosts = await callSingleProfileForMissingPosts(
                        sDate,
                        oldestTimestamp,
                        item,
                      );

                      console.log(newPosts);
                      if (newPosts === null) {
                        firstStoreReq = true;
                      }

                      if (newPosts !== null) {
                        await posts.push(...newPosts);
                        let mediaData = [...newPosts];
                        firstStoreReq = await axios
                          .post(ADDR + "/storeInstagramPosts", { mediaData })
                          .then((response) => {
                            console.log(response);
                            if (
                              response.status === 200 &&
                              response.data.message ===
                                "All operations completed"
                            ) {
                              console.log(
                                "in condition after storing call to DB",
                              );
                              return true;
                            }
                          });
                      }
                      newPosts = await callSingleProfileForMissingPosts(
                        newestTimestamp,
                        uDate,
                        item,
                      );
                      //Added this when posts doont exists then also it neeeds to display something
                      if (newPosts === null) {
                        secondStoreReq = true;
                      }

                      if (newPosts !== null) {
                        console.log("UNTIL MISSING POSTS: ", newPosts);
                        await posts.push(...newPosts);
                        let mediaData = [...newPosts];
                        secondStoreReq = await axios
                          .post(ADDR + "/storeInstagramPosts", { mediaData })
                          .then((response) => {
                            console.log(response);
                            if (
                              response.status === 200 &&
                              response.data.message ===
                                "All operations completed"
                            ) {
                              console.log(
                                "in condition after storing call to DB",
                              );
                              return true;
                            }
                          });
                      }

                      //Combined both new and old posts routines from above if blocks
                      //Now setting posts

                      console.log(firstStoreReq, "FREQ");
                      console.log(secondStoreReq, "SREC");

                      if (firstStoreReq === true && secondStoreReq === true) {
                        await getPostsFromDB(sinceDate, untilDate, item).then(
                          (response) => {
                            multipleProfileData.push(posts);

                            dispatch(
                              setLoaderModalStatus({
                                status: false,
                                message: "",
                              }),
                            );
                            return;
                          },
                        );
                      }
                    } else if (sDate < oldestTimestamp) {
                      dispatch(setMediaData([]));
                      dispatch(setMediaDataBackup([]));
                      console.log(sDate);
                      console.log("OT", oldestTimestamp);
                      console.log("SINCE DATE lesS THAN OLDEST POST");

                      //Call and write a new function here - passsing required time values here - instead of all 4 vars - to reuse function.
                      newPosts = await callSingleProfileForMissingPosts(
                        sDate,
                        oldestTimestamp,
                        item,
                      );

                      console.log("NNPS:", newPosts);

                      // if (newPosts == null) {
                      //   dispatch(
                      //     setLoaderModalStatus({
                      //       status: false,
                      //       message: "Fetching Data From Instagram",
                      //     }),
                      //   );
                      //   return;
                      // }

                      if (newPosts !== null) {
                        await posts.push(...newPosts);
                        let mediaData = [...newPosts];
                        await axios
                          .post(ADDR + "/storeInstagramPosts", { mediaData })
                          .then((response) => {
                            console.log(response);
                          });
                      }

                      console.log("trying to set, EP:", posts);
                      console.log("trying to set, NP:", newPosts);
                      multipleProfileData.push(posts);

                      dispatch(
                        setLoaderModalStatus({
                          status: false,
                          message: "",
                        }),
                      );
                      return;
                    } else if (newestTimestamp < uDate) {
                      dispatch(setMediaData([]));
                      dispatch(setMediaDataBackup([]));
                      //Do Nothing
                      //Future missing posts - storiong but not rendering posts corerectly
                      console.log("IN SECOND CONDITION");
                      newPosts = await callSingleProfileForMissingPosts(
                        newestTimestamp,
                        uDate,
                        item,
                      );

                      if (newPosts !== null) {
                        console.log("UNTIL MISSING POSTS: ", newPosts);
                        await posts.push(...newPosts);
                        let mediaData = [...newPosts];
                        await axios
                          .post(ADDR + "/storeInstagramPosts", { mediaData })
                          .then((response) => {
                            console.log(response);
                          });
                      }

                      console.log("trying to set, EP:", posts);
                      console.log("trying to set, NP:", newPosts);

                      multipleProfileData.push(posts);

                      dispatch(
                        setLoaderModalStatus({
                          status: false,
                          message: "",
                        }),
                      );
                      return;
                    } else if (sDate === oldestTimestamp) {
                      console.log("MATCH TIMES");
                    }

                    // multipleProfileData.push(posts);
                  });
              } else {
                console.log("CALLING INSTA");
                await updatePostsInDB();
                return;
              }
            });
        }),
      );
      //NO NEED TO FLATTEN
      console.log("AR", multipleProfileData);
      //Flatten the array / combine arrays
      let flattenedArray = multipleProfileData.flat().filter(Boolean);
      console.log("FA1:", flattenedArray);

      let uniquePosts = Object.values(
        flattenedArray.reduce((acc, post) => {
          // Check if the post id already exists in the accumulator
          if (acc[post.id]) {
            // Compare like_count and comments_count
            const existingPost = acc[post.id];
            if (
              post.like_count > existingPost.like_count ||
              post.comments_count > existingPost.comments_count
            ) {
              acc[post.id] = post; // Replace with the current post
            }
          } else {
            // Add new post to the accumulator
            acc[post.id] = post;
          }
          return acc;
        }, {}),
      );

      //NOT WORKING
      //          //Updating / rendering profile list to update expired and nonexistent images / numbers
      getProfileDataFromList(projectList);
      renderAccountList();
      dispatch(setMediaData([...uniquePosts]));
      dispatch(setMediaDataBackup([...uniquePosts]));
      dispatch(setMediaBackupForUsernameList([...uniquePosts]));
      setLoader(false);
      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );
    } catch (error) {
      if (
        error.response.data.error !== undefined &&
        error.response.data.error.code === 110
      ) {
        dispatch(
          setLoaderModalStatus({
            status: false,
            message: "",
          }),
        );

        dispatch(
          setErrorModalStatus({
            status: true,
            message:
              "Not A Business Profile : " +
                error.response.data.error_user_msg || "Not A Valid Profile",
          }),
        );
        return;
      }

      dispatch(
        setLoaderModalStatus({
          status: false,
          message: "",
        }),
      );

      dispatch(
        setErrorModalStatus({
          status: true,
          message: "Something Went Wrong",
        }),
      );

      console.error("Error fetching data: ", error);
    }
  };

  function toggleMenu() {
    const menu = document.getElementById("floatingMenu");
    menu.classList.toggle("hidden");
  }

  const goToPostOnInstagram = (link) => {
    console.log(link);
    window.open(link, "_blank");
  };

  let calculateEngagementRate = (likes, comments) => {
    let followers = profileInfoModal.followersCount;
    let engagementRate = (likes + comments) / followers;
    return engagementRate.toFixed(2);
  };

  let calculateReach = (likes, comments) => {
    // Estimate reach based on a simple multiplication of engagement by a factor
    // Assume reach is proportional to the number of engagements (likes + comments)
    let reach = likes + comments;

    // Function to format the number into k, m, etc.
    const formatNumber = (num) => {
      if (num >= 1e9) {
        return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "b";
      } else if (num >= 1e6) {
        return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "m";
      } else if (num >= 1e3) {
        return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
      } else {
        return num.toString();
      }
    };

    return formatNumber(reach);
  };

  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1).replace(/\.0$/, "") + "B"; // Billions
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1).replace(/\.0$/, "") + "M"; // Millions
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(1).replace(/\.0$/, "") + "K"; // Thousands
    }
    return num.toString(); // Numbers below 1000
  }

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;

    const date = new Date(dateString);
    const timestamp = date.getTime();
    if (!timestamp && timestamp !== 0) return false;

    return date.toISOString().slice(0, 10) === dateString;
  }

  function handleSinceDateChange(e) {
    const value = e.target.value;
    if (isValidDate(value)) {
      setTempSinceDate(new Date(value));
    } else {
      console.warn("Invalid date input:", value);
    }
  }
  function handleUntilDateChange(e) {
    const value = e.target.value;
    if (isValidDate(value)) {
      setTempUntilDate(new Date(value));
    } else {
      console.warn("Invalid date input:", value);
    }
  }

  const unSelectAndRemoveTick = () => {
    const radios = document.querySelectorAll('input[name="profiles"]');
    radios.forEach((radio) => {
      radio.checked = false;
    });
    console.log("All radio buttons unchecked.");

    dispatch(setSelectedUsernameList([]));
  };

  const checkboxHandler = (e) => {
    console.log(e);

    let list = [...selectedUsernameList];
    console.log("Before setting: ", list);
  };

  const handleSelection = (type) => {
    const defaultCss = "";
    const selectedCss = "font-bold text-darkbgone dark:text-lightbgtwo";

    // setIsMediaIconWhite(type === "mediatype");
    // setIsLikeIconWhite(type === "likes");
    // setIsCommentIconWhite(type === "comments");
    // setIsCalendarIconWhite(type === "date");
    // setIsEngagementIconWhite(type === "engagement");
    // setIsReachIconWhite(type === "reach");
    // setIsUsernameIconWhite(type === "username");
    dispatch(
      setLikesSelected({
        css: type === "likes" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setCommentsSelected({
        css: type === "comments" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setDateSelected({
        css: type === "date" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setEngagementSelected({
        css: type === "engagement" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setReachSelected({
        css: type === "reach" ? selectedCss : defaultCss,
      }),
    );
    dispatch(
      setUsernameSelected({
        css: type === "username" ? selectedCss : defaultCss,
      }),
    );

    dispatch(
      setMediaTypeSelected({
        css: type === "mediatype" ? selectedCss : defaultCss,
      }),
    );
  };

  const sortByLikes = () => {
    let dataTemp = [...mediaData];
    let sortedArray;

    if (isDescending) {
      sortedArray = dataTemp.sort((a, b) => b.like_count - a.like_count);
    } else {
      sortedArray = dataTemp.sort((a, b) => a.like_count - b.like_count);
    }

    handleSelection("likes");
    setLikeLabelBold("font-bold text-white");

    dispatch(setIsDescending(!isDescending));

    dispatch(setMediaData([...sortedArray]));
  };

  const sortByComments = () => {
    let dataTemp = [...mediaData];
    let sortedArray;
    handleSelection("comments");

    if (isDescendingComments) {
      sortedArray = dataTemp.sort(
        (a, b) => b.comments_count - a.comments_count,
      );
    } else {
      sortedArray = dataTemp.sort(
        (a, b) => a.comments_count - b.comments_count,
      );
    }

    dispatch(setIsDescendingComments(!isDescendingComments));

    dispatch(setMediaData([...sortedArray]));
  };

  const sortByDate = () => {
    let dataTemp = [...mediaData];
    let sortedLatest;
    if (isLatest) {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      );
    } else {
      sortedLatest = dataTemp.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      );
    }
    handleSelection("date");

    dispatch(setIsLatest(!isLatest));

    dispatch(setMediaData([...sortedLatest]));
  };

  let sortByUsernameAlphabetically = () => {
    let dataTemp = [...mediaData];
    let sortedArray = dataTemp.sort((a, b) => {
      // Compare usernames alphabetically
      if (a.username.toLowerCase() < b.username.toLowerCase()) {
        return isUsername ? -1 : 1; // Ascending if isReach is true, else Descending
      }
      if (a.username.toLowerCase() > b.username.toLowerCase()) {
        return isUsername ? 1 : -1; // Ascending if isReach is true, else Descending
      }
      return 0; // If they are equal
    });
    handleSelection("username");
    dispatch(setIsUsername(!isUsername));
    dispatch(setMediaData([...sortedArray]));
  };

  let sortByReach = () => {
    // Create a copy of the mediaData array to avoid mutating the original state
    let dataTemp = [...mediaData];

    // Calculate the reach for each post and sort the array
    let sortedArray = dataTemp.sort((a, b) => {
      // Calculate reach for post 'a'
      let reachA = a.like_count + a.comments_count;

      // Calculate reach for post 'b'
      let reachB = b.like_count + b.comments_count;

      // Determine sort order: Ascending if isReach is true, else Descending
      if (isReach) {
        return reachA - reachB; // Ascending order
      } else {
        return reachB - reachA; // Descending order
      }
    });

    handleSelection("reach");

    // Toggle the sorting order state
    dispatch(setIsReach(!isReach));

    // Update the media data with the sorted array
    dispatch(setMediaData([...sortedArray]));
  };

  const sortByEngagement = () => {
    // Create a copy of the mediaData array to avoid mutating the original state
    let dataTemp = [...mediaData];

    // Calculate the engagement rate for each post and sort the array
    let sortedArray = dataTemp.sort((a, b) => {
      // Calculate engagement rate for post 'a'
      let engagementRateA =
        (a.like_count + a.comments_count) / profileInfoModal.followersCount;

      // Calculate engagement rate for post 'b'
      let engagementRateB =
        (b.like_count + b.comments_count) / profileInfoModal.followersCount;

      // Determine sort order: Ascending if isEngagement is true, else Descending
      if (isEngagement) {
        return engagementRateA - engagementRateB; // Ascending order
      } else {
        return engagementRateB - engagementRateA; // Descending order
      }
    });

    handleSelection("engagement");

    // Toggle the sorting order state
    dispatch(setIsEngagement(!isEngagement));

    // Update the media data with the sorted array
    dispatch(setMediaData([...sortedArray]));
  };

  const backToProjectList = () => {
    window.location.replace("/projects");
    dispatch(
      setCurrentSelectedProject({
        projectName: "",
        projectList: [],
      }),
    );
  };

  const getProfileDataFromList = async (usernameList) => {
    let response = await axios
      .post(ADDR + "/getProfilesFromDB", { usernameList })
      .then((response) => {
        console.log(response);
        return response.data;
      });

    let profiles = response.profiles;

    console.log("ProfileData:", profiles);

    dispatch(setMultipleProfileData([...profiles]));
  };

  const addNewAccountToProject = async () => {
    //Add a new username to list and refresh the list / page.
    //Get existing usernames first and update to DB.

    dispatch(
      setEditProjectModal({
        status: true,
        projectName: projectName,
        createdDate: new Date().toDateString(),
        lastUsed: new Date().toDateString(),
        lastModified: new Date().toDateString(),
        usernames: [...projectList],
      }),
    );

    // navigate("/home", {
    //   state: {
    //     projectName: projectName,
    //     projectList: projectList,
    //   },
    // });

    //Account is getting addded - but is not coming in realtime in dashboard unless gone back to projects and opened back

    //PROFILES NOT ADDING IN REALTIME - WILL DO LATER
  };

  const handleClick = (e) => {
    const value = e.target.dataset.value;
    let dataTemp = [...mediaDataBackup]; // Always start from the unfiltered data
    let filteredData = [];

    handleSelection("mediatype");
    // Perform tasks based on the value
    switch (value) {
      case "all":
        console.log("All media selected");
        setFilterLabel("All");
        toggleMenu();
        //Do nothing
        filteredData = [...mediaDataBackup];
        dispatch(setMediaData([...filteredData]));

        break;
      case "carousel":
        console.log("Carousel media selected");
        setFilterLabel("Carousel");
        toggleMenu();
        filteredData = dataTemp.filter(
          (item) => item.media_type === "CAROUSEL_ALBUM",
        );
        dispatch(setMediaData([...filteredData]));

        break;
      case "image":
        console.log("Image media selected");
        setFilterLabel("Image");
        toggleMenu();
        filteredData = dataTemp.filter((item) => item.media_type === "IMAGE");
        dispatch(setMediaData([...filteredData]));

        break;
      case "video":
        console.log("Video media selected");
        setFilterLabel("Video");
        toggleMenu();
        filteredData = dataTemp.filter((item) => item.media_type === "VIDEO");
        dispatch(setMediaData([...filteredData]));

        break;
      default:
        console.log("Unknown selection");
    }
  };

  const renderAccountList = () => {
    return multipleProfileData.flat().map((item, index) => {
      return (
        <div
          key={index}
          className="w-[330px] sm:w-full lg:w-full lg:justify-center gap-5 py-2 px-1 mt-2 flex flex-row rounded-[12px] bg-gradient-to-b from-darkbggradientfrom to-darkbggradientto shadow-lg"
        >
          <ImageWithFallback
            src={item.profile_picture_url}
            alt={item.name}
            placeholder={dp} // Fallback image
          />

          <div className="flex flex-col -ml-5 text-lightbgtwo">
            <p className="w-[200px] text-left font-semibold px-2">
              {item.name || item.username}
            </p>
            <p className="w-[200px] text-sm px-2 text-left flex gap-1">
              {formatNumber(item.followersCount)} Followers
              <Ellipse className="my-2" /> {formatNumber(item.totalMediaCount)}{" "}
              Posts
            </p>
          </div>
          <div className="flex items-center justify-center rounded-full">
            <input
              name="profiles"
              onChange={(e) => {
                if (projectList.length > 0) {
                  // Backup array
                  console.log("BackupList:", projectList);
                }

                console.log(e);
                let list = [...selectedUsernameList];
                console.log("List:", list);

                if (e.target.checked === true) {
                  // Add to list
                  list.push(item.username);
                  //   dispatch(setMediaData([...mediaDataBackup]));
                } else if (e.target.checked === false) {
                  // Remove from list
                  list = list.filter((username) => username !== item.username);
                }

                console.log("List:", list);

                // Removing duplicates from list
                let uniqueArray = [...new Set(list)];
                console.log("Unique List:", uniqueArray);

                dispatch(setSelectedUsernameList(uniqueArray));
              }}
              type="checkbox"
              defaultChecked={true}
              className="w-8 h-8 rounded-full  cursor-pointer"
            />
          </div>
        </div>
      );
    });
  };

  //This needs to be checked - working but not setting
  //To render retrieved posts based on username
  useEffect(() => {
    //If there is backup - restore MediaData first

    console.log("selectedList:", selectedUsernameList);

    // if (mediaDataBackup.length > 0) {
    //   console.log("bkup:", mediaDataBackup);
    //   dispatch(setMediaData([...mediaDataBackup]));
    //   return;
    // }

    // if (mediaDataBackupForUsernameList.length > 0) {
    //   console.log("bkup:", mediaDataBackupForUsernameList);
    //   dispatch(setMediaData([...mediaDataBackupForUsernameList]));
    //   return;
    // }

    // Take backup of MediaData
    // dispatch(setMediaDataBackup(mediaData));
    // dispatch(setMediaBackupForUsernameList(mediaData));

    console.log("MDATA:", mediaDataBackupForUsernameList);
    const filteredData = mediaDataBackupForUsernameList.filter((item) =>
      selectedUsernameList.includes(item.username),
    );

    console.log("FDATA:", filteredData);

    //setting mediaData
    dispatch(setMediaData(filteredData));
  }, [selectedUsernameList]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedMediaType, setSelectedMediaType] = useState("all");

  useEffect(() => {
    if (loading) {
      // show loader (you can manage this by setting a state or directly within JSX)
      return; // Early return to prevent further execution
    }

    if (user) {
      //Do nothing
    } else {
      window.location.replace("/login");
    }
  }, [user, loading]);

  //To avoid duplicates wheenver mediaData is set remove duplicates and reset it
  useEffect(() => {
    //remove duplicates if any

    let uniqueArray = [...new Set(mediaData)];

    dispatch(setNewMediaData(uniqueArray));
  }, [mediaData]);

  //To simulate save button in settings in v1
  useEffect(() => {
    const unixSinceDateTimestamp = Math.floor(tempSinceDate.getTime() / 1000);
    console.log("Unix Since Date:", unixSinceDateTimestamp);
    dispatch(setRequestSettingSinceDate({ sinceDate: unixSinceDateTimestamp }));
  }, [tempSinceDate]);

  useEffect(() => {
    const unixUntilDateTimestamp = Math.floor(tempUntilDate.getTime() / 1000);
    console.log("Unix Until Date:", unixUntilDateTimestamp);
    dispatch(setRequestSettingUntilDate({ untilDate: unixUntilDateTimestamp }));
  }, [tempUntilDate]);

  useEffect(() => {
    getProfileDataFromList(projectList);
    console.log("Updated list: ", projectList);
  }, [projectList]);

  useEffect(() => {
    console.log(projectName);
    console.log("PL:", projectList);
    dispatch(
      setCurrentSelectedProject({
        projectName: projectName,
        projectList: projectList,
      }),
    );
    let list = [...projectList];

    let uniqueArray = [...new Set(list)];
    console.log("Unique ListLL:", uniqueArray);

    dispatch(setSelectedUsernameList(uniqueArray));

    getMultipleProfilePosts(uniqueArray);
  }, [projectName, projectList]);

  const ImageWithFallback = ({ src, alt, placeholder }) => {
    const [imgSrc, setImgSrc] = useState(src);

    const handleError = () => {
      setImgSrc(placeholder); // Set the fallback image if there's an error
    };

    return (
      <img
        src={imgSrc}
        alt={alt}
        onError={handleError}
        className="h-[45px] w-[45px] rounded-full"
      />
    );
  };

  //Create a  list based on tickmarks / checkboxes and use this list as usernameList for api / funciton calls.

  return (
    <div className="flex justify-center mt-36 w-full min-h-[600px] max-h-screen">
      {/* Left Side */}
      <div className="w-2/6 sm:w-1/4 mx-5">
        <h1
          className="text-left text-grey hover:text-darkbgone dark:hover:text-lightbgtwo hover:cursor-pointer"
          onClick={backToProjectList}
        >
          {"<"} Projects
        </h1>

        <div className="flex justify-between">
          <h1 className="text-left text-3xl font-semibold my-5">
            {projectName}
          </h1>

          {/* <button
                onClick={addNewAccountToProject}
                class="my-6 bg-lightbgtwo dark:bg-darkbgthree rounded-full h-8 w-8 flex items-center justify-center hover:invert"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button> */}
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex gap-2">
            <h1 className="font-semibold">Accounts</h1>
            <h1 className="text-grey font-bold">{projectList.length}</h1>
          </div>

          <div>
            <button
              onClick={unSelectAndRemoveTick}
              className="text-left hover:font-semibold cursor-pointer"
            >
              Deselect all
            </button>
          </div>
        </div>

        {renderAccountList()}
      </div>
      {/* {"nov 23"} */}
      {/* List */}
      {/* Top level container for posts/ */}
      <div class="bg-lightbgtwo dark:bg-darkbgtwo flex flex-col w-4/6 sm:w-3/4 max-w-[1000px] rounded-[20px] px-5 pb-5 border border-grey">
        {/* Header */}

        <div className="flex w-full justify-between">
          <div>
            <h1 className="text-3xl font-semibold my-5">Analytics</h1>
          </div>

          {/* settinmgs part */}

          <div className="flex gap-1">
            {" "}
            <div className="flex flex-col justify-center mx-2">
              <Search
                onClick={openSearchCaptionModal}
                className="cursor-pointer"
              />
            </div>
            <div class="relative inline-block font-semibold">
              {/* BEfore this */}

              <button
                onClick={toggleMenu}
                class="w-[145px] my-5 bg-lightbgthree dark:bg-darkbgthree rounded-[12px] px-4 py-2.5 flex items-center justify-between space-x-2 hover:bg-gray-200 dark:hover:bg-darkbgfour focus:outline-none"
              >
                <span>{filterLabel}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              <div
                id="floatingMenu"
                class="hidden absolute text-left font-semibold left-0 w-[145px] bg-lightbgthree dark:bg-darkbgthree rounded-[12px] shadow-lg z-50"
              >
                <a
                  class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                  data-value="all"
                  onClick={handleClick}
                >
                  All
                </a>
                <a
                  class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                  data-value="carousel"
                  onClick={handleClick}
                >
                  Carousel
                </a>
                <a
                  class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                  data-value="image"
                  onClick={handleClick}
                >
                  Image
                </a>
                <a
                  class="block mx-1 my-1 px-4 py-2 text-darkbgone dark:text-lightbgone hover:bg-lightbgtwo dark:hover:bg-darkbgfour rounded-[12px] hover:cursor-pointer"
                  data-value="video"
                  onClick={handleClick}
                >
                  Video
                </a>
              </div>
            </div>
            <input
              class="bg-lightbgthree dark:bg-darkbgthree rounded-[12px] font-semibold  px-4 py-2 flex items-center space-x-2 my-5 border border-none "
              type="date"
              id="until"
              value={formatDate(tempSinceDate)}
              onChange={handleSinceDateChange}
            />
            <Hiphen className="my-10 mx-1" />{" "}
            <div>
              <input
                class="custom-date bg-lightbgthree dark:bg-darkbgthree rounded-[12px] font-semibold  px-4 py-2 flex items-center space-x-2 my-5 border border-none "
                type="date"
                id="until"
                value={formatDate(tempUntilDate)}
                onChange={handleUntilDateChange}
              />
            </div>
            <button
              onClick={getMultipleProfilePosts}
              className="bg-primary text-lightbgtwo w-[120px] h-[40px] rounded-[12px] font-semibold my-5"
            >
              Update
            </button>
          </div>
        </div>

        {/* Table Header  */}

        {/* Table Header */}
        <div className="overflow-x-auto h-[600px] ">
          <table className="min-w-full table-auto ">
            <thead className="w-full sticky top-0 bg-lightbgtwo dark:bg-darkbgthree z-10">
              <tr className="text-grey dark:text-grey border-b border-grey">
                <th
                  className={`select-none px-4 py-2 text-left cursor-not-allowed ${mediaTypeSelected.css}`}
                >
                  Media
                </th>
                <th
                  onClick={sortByLikes}
                  className={`select-none px-4 py-2 text-left  cursor-pointer ${likesSelected.css}`}
                >
                  Like
                </th>
                <th
                  onClick={sortByComments}
                  className={`select-none px-4 py-2 text-left  cursor-pointer ${commentsSelected.css}`}
                >
                  Comments
                </th>
                <th
                  onClick={sortByDate}
                  className={`select-none px-4 py-2 text-left  cursor-pointer ${dateSelected.css}`}
                >
                  Date
                </th>
                <th
                  onClick={sortByUsernameAlphabetically}
                  className={`select-none px-4 py-2 text-left  cursor-pointer ${usernameSelected.css}`}
                >
                  Account
                </th>
                <th
                  onClick={sortByEngagement}
                  className={`select-none px-4 py-2 text-left  cursor-pointer ${engagementSelected.css}`}
                >
                  Engagement
                </th>
                <th
                  onClick={sortByReach}
                  className={`select-none px-4 py-2 text-left  cursor-pointer ${reachSelectedSelected.css}`}
                >
                  Reach
                </th>
                <th className=" cursor-not-allowed	 bg-lightbgtwo dark:bg-darkbgthree px-4 py-2 text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="overflow-y-auto">
              {loader === true && (
                <tr
                  // onClick={() => goToPostOnInstagram(item.permalink)}
                  // key={item.id}
                  className="border-none  hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone text-darkbgone dark:text-lightbgone border-b border-grey"
                >
                  <td colSpan="8" className="px-4 py-4 text-center">
                    <div className="w-full mt-32 h-24 my-4 text-center justify-center flex">
                      <LoadingIcons.Puff
                        stroke="#9E9EA4"
                        fill="#9E9EA4"
                        height="120px"
                        width="120px"
                      />
                    </div>
                    <br />

                    <p className="text-3xl text-center font-semibold text-darkbgone dark:text-lightbgone">
                      {"Loading"}
                    </p>
                    <br />
                  </td>
                </tr>
              )}

              {loader === false &&
              newMediaData !== undefined &&
              newMediaData.length > 0 ? (
                newMediaData.map((item) => (
                  <tr
                    // onClick={() => goToPostOnInstagram(item.permalink)}
                    key={item.id}
                    className="hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone hover:bg-darkbgone hover:text-lightbgone text-darkbgone dark:text-lightbgone border-b border-grey"
                  >
                    <td
                      className={`px-4 py-2 text-left ${mediaTypeSelected.css}`}
                    >
                      {item.media_type === "CAROUSEL_ALBUM" ? (
                        <p>Carousel Album</p>
                      ) : item.media_type === "IMAGE" ? (
                        <p>Image</p>
                      ) : item.media_type === "VIDEO" ? (
                        <p>Video</p>
                      ) : (
                        <p>Unknown media type</p>
                      )}
                    </td>
                    <td className={`px-4 py-2 text-left ${likesSelected.css}`}>
                      {formatNumber(item.like_count)}
                      {item.like_count === undefined && <p>Likes Hidden</p>}
                    </td>

                    <td
                      className={`px-4 py-2 text-left ${commentsSelected.css}`}
                    >
                      {formatNumber(item.comments_count)}
                    </td>
                    <td className={`px-4 py-2 text-left ${dateSelected.css}`}>
                      {new Date(item.timestamp).getFullYear() +
                        "-" +
                        (new Date(item.timestamp).getMonth() + 1) +
                        "-" +
                        new Date(item.timestamp).getDate()}
                    </td>

                    <td
                      className={`px-4 py-2 text-left ${usernameSelected.css}`}
                    >
                      {item.username}
                    </td>

                    <td
                      className={`px-4 py-2 text-left ${engagementSelected.css}`}
                    >
                      {" "}
                      {calculateEngagementRate(
                        item.like_count || 0,
                        item.comments_count,
                      ) + "%"}
                    </td>

                    <td
                      className={`px-4 py-2 text-left ${reachSelectedSelected.css}`}
                    >
                      {" "}
                      {calculateReach(
                        item.like_count || 0,
                        item.comments_count,
                      ) + " Users"}
                    </td>

                    <td className="px-4 py-2">
                      <div className="flex justify-end space-x-2">
                        <button
                          className="hover:scale-110"
                          onClick={(e) => {
                            e.stopPropagation();
                            // deleteEntry(item);
                            goToPostOnInstagram(item.permalink);
                          }}
                        >
                          {/* <DeleteIcon className="w-6 h-6 rounded-full text-darkbgone dark:text-lightbgone" /> */}

                          <VectorSvg className="w-4 h-8 rounded-full text-darkbgone dark:text-lightbgone" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}

              {/* {loader === true &&
              newMediaData !== undefined &&
              newMediaData.length > 0 ? (
                <tr className="hover:cursor-pointer dark:hover:bg-lightbgone dark:hover:text-darkbgone hover:bg-darkbgone hover:text-lightbgone text-darkbgone font-semibold dark:text-lightbgone border-b border-grey">
                  <td colSpan="8" className="px-4 py-4 text-center">
                    Select a profile from the list and click 'Update' to fetch
                    data.{" "}
                  </td>
                </tr>
              ) : (
                <></>
              )} */}
            </tbody>
          </table>
        </div>
        {/* 
        <FacebookLoginComponent /> */}
      </div>{" "}
      <ErrorModal />
      <SearchCaptionModal />
      <EditProjectModal />
    </div>
  );
};

export default NewHome;
